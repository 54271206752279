import { Action } from '@ngrx/store';
import { AnyModelStr } from 'src/app/shared/types/any-models.types';
import { FeedbackNeeded } from 'src/app/shared/types/feedback-needed.types';
import { Feedback } from 'src/app/shared/types/feedback.types';

export const FEEDBACKS_SET_INITIAL = '[FEEDBACKS] Set initial';
export const FEEDBACKS_LOAD = '[FEEDBACKS] Load feedbacks';
export const FEEDBACKS_LOAD_SUCCESS = '[FEEDBACKS] Load feedbacks successful';
export const FEEDBACKS_LOAD_ERROR = '[FEEDBACKS] Load feedbacks failed';
export const FEEDBACKS_LOAD_BY_SUBMISSION = '[FEEDBACKS] Load feedbacks by submission';
export const FEEDBACKS_LOAD_BY_SUBMISSION_SUCCESS = '[FEEDBACKS] Load feedbacks by submission successful';
export const FEEDBACKS_LOAD_BY_SUBMISSION_ERROR = '[FEEDBACKS] Load feedbacks by submission failed';
export const FEEDBACK_LOAD = '[FEEDBACK] Load feedback';
export const FEEDBACK_LOAD_SUCCESS = '[FEEDBACK] Load feedback successful';
export const FEEDBACK_LOAD_ERROR = '[FEEDBACK] Load feedback failed';
export const UPDATE_FEEDBACKS = '[FEEDBACKS] Update feedbacks';
export const UPDATE_FEEDBACKS_SUCCESS = '[FEEDBACKS] Update feedbacks successful';
export const UPDATE_FEEDBACKS_ERROR = '[FEEDBACKS] Update feedbacks failed';
export const UPDATE_FEEDBACK = '[FEEDBACKS] Update feedback';
export const UPDATE_FEEDBACK_SUCCESS = '[FEEDBACKS] Update feedback successful';
export const UPDATE_FEEDBACK_ERROR = '[FEEDBACKS] Update feedback failed';
export const FEEDBACKS_NEEDED_LOAD_SUCCESS = '[FEEDBACKS] Load missing feedbacks successful';
export const FEEDBACKS_NEEDED_LOAD_ERROR = '[FEEDBACKS] Load missing feedbacks failed';

export class FeedbacksSetInitial implements Action {
  readonly type = FEEDBACKS_SET_INITIAL;
}
export class FeedbacksLoad implements Action {
  readonly type = FEEDBACKS_LOAD;
  constructor(public forceUpdate: boolean = false) {}
}

export class FeedbacksLoadSuccess implements Action {
  readonly type = FEEDBACKS_LOAD_SUCCESS;
  constructor(public payload: Feedback[]) {}
}

export class FeedbacksLoadError implements Action {
  readonly type = FEEDBACKS_LOAD_ERROR;
}

export class FeedbacksLoadBySubmission implements Action {
  readonly type = FEEDBACKS_LOAD_BY_SUBMISSION;
  constructor(public modelType: AnyModelStr, public id: string) {}
}

export class FeedbacksLoadBySubmissionSuccess implements Action {
  readonly type = FEEDBACKS_LOAD_BY_SUBMISSION_SUCCESS;
  constructor(public feedbacks: Feedback[]) {}
}

export class FeedbacksLoadBySubmissionError implements Action {
  readonly type = FEEDBACKS_LOAD_BY_SUBMISSION_ERROR;
}

export class FeedbackLoad implements Action {
  readonly type = FEEDBACK_LOAD;
  constructor(public id: string) {}
}

export class FeedbackLoadSuccess implements Action {
  readonly type = FEEDBACK_LOAD_SUCCESS;
  constructor(public payload: Feedback) {}
}

export class FeedbackLoadError implements Action {
  readonly type = FEEDBACK_LOAD_ERROR;
}

export class FeedbackUpdate implements Action {
  readonly type = UPDATE_FEEDBACK;
  constructor(public feedback: Feedback) {}
}

export class FeedbackUpdateSuccess implements Action {
  readonly type = UPDATE_FEEDBACK_SUCCESS;
  constructor(public payload: Feedback) {}
}

export class FeedbackUpdateError implements Action {
  readonly type = UPDATE_FEEDBACK_ERROR;
  constructor(public error: string) {}
}

export class FeedbacksNeededLoadSuccess implements Action {
  readonly type = FEEDBACKS_NEEDED_LOAD_SUCCESS;
  constructor(public payload: FeedbackNeeded[]) {}
}

export class FeedbacksNeededLoadError implements Action {
  readonly type = FEEDBACKS_NEEDED_LOAD_ERROR;
}

export type Actions =
  | FeedbacksSetInitial
  | FeedbacksLoad
  | FeedbacksLoadSuccess
  | FeedbacksLoadError
  | FeedbacksLoadBySubmission
  | FeedbacksLoadBySubmissionSuccess
  | FeedbacksLoadBySubmissionError
  | FeedbackLoad
  | FeedbackLoadSuccess
  | FeedbackLoadError
  | FeedbackUpdate
  | FeedbackUpdateSuccess
  | FeedbackUpdateError
  | FeedbacksNeededLoadSuccess
  | FeedbacksNeededLoadError;
