import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AnyModelStr } from '../types/any-models.types';
import { Feedback } from '../types/feedback.types';
import { FeedbackNeeded } from '../types/feedback-needed.types';
@Injectable({
  providedIn: 'root',
})
export class FeedbackService {
  constructor(private http: HttpClient) {}

  /**
   * Returns feedbacks
   * @returns feedbacks
   */
  public getAllFeedbacks(params: HttpParams) {
    return this.http.get<Feedback[]>(environment.apiUrl + '/feedbacks/', { params: params });
  }

  /**
   * Returns feedbacks
   * @returns feedbacks
   */
  public getAllFeedbacksBySubmissionId(modelType: AnyModelStr, id: string) {
    return this.http.get<Feedback[]>(environment.apiUrl + `/${modelType}/feedback/${id}/`);
  }

  /**
   * Returns feedback
   * @returns feedback
   */
  public getFeedbackDetail(id: string) {
    return this.http.get<Feedback>(environment.apiUrl + `/feedback/find/${id}/`);
  }

  /**
   * Returns feedback
   * @returns feedback
   */
  public updateFeedback(feedback: Feedback) {
    return this.http.post<Feedback>(environment.apiUrl + `/feedback/update/${feedback.id}/`, { updated_feedback: feedback });
  }

  /**
   * Returns triggered emails
   * @returns Returns triggered emails
   */
  public getTriggeredEmails(feedback: Feedback) {
    return this.http.get<{ title: string; id: number }[]>(environment.apiUrl + `/feedback/triggered_emails/${feedback.id}/`);
  }

  /**
   * Returns triggered emails
   * @returns Returns triggered emails
   */
  public getTriggeredEmailsById(feedback_id: string) {
    return this.http.get<{ title: string; id: number }[]>(environment.apiUrl + `/feedback/triggered_emails/${feedback_id}/`);
  }

  /**
   * Returns list of emails that were supposed to be recieved
   * @returns Returns missing emails
   */
  public getFeedbackNeeded() {
    return this.http.get<FeedbackNeeded[]>(environment.apiUrl + `/feedbacks_needed/`);
  }
}
